import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {ThemeProvider} from "./themes/ThemeProvider";
import {AssessmentResultPage} from "./pages/assessment/AssessmentResultPage";
import {AssessmentResultPurchasePage} from "./pages/assessment/AssessmentResultPurchasePage";
import {FaqPage} from "./pages/faq/FaqPage";
import {AssessmentRegisterPage} from "./pages/assessment/AssessmentRegisterPage";
import {AssessmentLoginPage} from "./pages/assessment/AssessmentLoginPage";
import ReactGA from 'react-ga';

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
      fontSize: '18px',
      lineHeight: "initial",
      fontFamily: 'Poppins, sans-serif'
    }
  },
  shape: {
    borderRadius: ThemeProvider.properties.roundness
  },
  palette: {
    primary: {
      main: ThemeProvider.colors.default
    },
    secondary: {
      main: ThemeProvider.colors.danger
    }
  }
});

function App() {

  ReactGA.initialize('UA-172770971-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/" component={AssessmentRegisterPage}/>
          <Route path="/login" component={AssessmentLoginPage}/>
          <Route path="/resultado/:candidateGuid" component={AssessmentResultPage}/>
          <Route path="/faq" component={FaqPage}/>
          <Route path="/compra/:candidateGuid" component={AssessmentResultPurchasePage}/>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
