import React from "react";
import ReactLoading from 'react-loading';
import {StyleSheet} from "../styles/StyleSheet";

export type Props = {
  loading: boolean
}


export class Loading extends React.Component<Props> {
  render() {
    return this.props.loading && <div style={styles.loading}>
      <ReactLoading type={'bars'} height={100} width={50}/>
    </div>
      ;
  }
}

const styles = StyleSheet.create({
  loading: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#00000080',
    zIndex: 10,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }
});



