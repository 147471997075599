import React, {Component} from "react";
import {StyleSheet} from '../../styles/StyleSheet'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {Poppins, Roboto} from "../../components/StyledText";
import {Spacer} from "../../components/Spacer";
import {TheColumnsLayout} from "../../layouts/the-columns/TheColumnsLayout";
import FeedbackIcon from '@material-ui/icons/Feedback';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PoolIcon from '@material-ui/icons/Pool';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import InfoIcon from '@material-ui/icons/Info';
import {LightTooltip} from "../../components/Tooltip";
import {TextField} from "../../components/TextField";
import {AssessmentService} from "../../services/AssessmentService";
import {Loading} from "../../components/Loading";

export type Props = {
  candidateGuid: string;
};

export type States = {
  reportFullProfileSelected: boolean;
  reportSalesAuditSelected: boolean;
  reportLeadershipSkillsSelected: boolean;
  reportStrengthsAndLimitationsSelected: boolean;
  reportTrainingNeedsSelected: boolean;
  reportManagementAuditSelected: boolean;
  reportManagingRemoteWorkersSelected: boolean;
  reportCandidateFeedbackSelected: boolean;
  reportPersonalReviewSelected: boolean;
  reportsSelected: number[];

  creditCardNumber: string;
  creditCardHolder: string;
  creditCardExpiry: string;
  creditCardHolderDocument: string;
  creditCardCVV: string;

  assessmentGuid: string;

  reports: any[];
  loading: boolean;
  dialogShow: boolean;
  dialogTitle: string;
  dialogMessage: string;
  dialogType: "payment-success" | "payment-error";
};

export class AssessmentResultPurchasePage extends Component<Props, States> {
  candidateGuid: string = window.location.href.split('/')[window.location.href.split('/').length - 1];

  constructor(props: Props) {
    super(props);
    this.state = {
      reportFullProfileSelected: false,
      reportSalesAuditSelected: false,
      reportLeadershipSkillsSelected: false,
      reportStrengthsAndLimitationsSelected: false,
      reportTrainingNeedsSelected: false,
      reportManagementAuditSelected: false,
      reportManagingRemoteWorkersSelected: false,
      reportCandidateFeedbackSelected: false,
      reportPersonalReviewSelected: false,
      reportsSelected: [],

      creditCardNumber: '',
      creditCardHolder: '',
      creditCardExpiry: '',
      creditCardHolderDocument: '',
      creditCardCVV: '',
      assessmentGuid: '',
      reports: [{}],
      loading: true,

      dialogShow: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogType: 'payment-error'
    }
  }

  async componentDidMount() {
    let data: any = await AssessmentService.getResult(this.candidateGuid);
    let reports: any = await AssessmentService.getAvailableProducts(data.assessmentGuid);
    await this.setState({reports: reports});
    await this.setState({assessmentGuid: data.assessmentGuid});
    await this.setState({loading: false});
  }

  _refCreditCardNumber ?: TextField | null;
  _refCreditCardHolder ?: TextField | null;
  _refCreditCardExpiry ?: TextField | null;
  _refCreditCardCVV ?: TextField | null;
  _refCreditCardHolderDocument ?: TextField | null;

  render() {
    return (
      <TheColumnsLayout leftBackgroundColor={'#e27983'}>
        <TheColumnsLayout.LeftColumnContent>
          <Poppins
            size={'35px'}
            fontWeight={"bold"}
            color={'#ffffff'}>
            Relatório comportamental completo PPA DISC
          </Poppins>
          <Spacer/>
          <LightTooltip
            hideInfoIcon={true}
            title={
              <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                <b>Devolutiva Individual:</b> Sessão agendada de 45 minutos com um especialista DISC da Thomas
                International, realizada através de vídeo chamada!
              </Roboto>
            }>
            <Poppins
              size={'35px'}
              fontWeight={"bold"}
              color={'#ffffff'}>
              + Devolutiva Especializada <InfoIcon style={{color: '#ffffff', fontSize: '30px'}}/>
            </Poppins>
          </LightTooltip>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            Com o relatório completo PPA DISC você obterá valiosas informações para o seu processo de autoconhecimento e
            compreenderá melhor sua relação com o trabalho, além de uma devolutiva individual com um especialista que
            lhe dará ampla consciência e entendimento sobre seu atual perfil comportamental e como explorá-lo nas suas
            relações profissionais e interpessoais.
          </Roboto>
          <Spacer size={2}/>
          {/*<Poppins*/}
          {/*  size={'35px'}*/}
          {/*  style={{display: 'table', padding: '0px 10px', marginLeft: '-10px'}}*/}
          {/*  fontWeight={"bold"}*/}
          {/*  color={'#ffffff'}>*/}
          {/*  De R$ 301,00*/}
          {/*</Poppins>*/}
          {/*<Poppins*/}
          {/*  size={'40px'}*/}
          {/*  fontWeight={"bold"}*/}
          {/*  color={'#ffffff'}*/}
          {/*  style={{*/}
          {/*    backgroundColor: ThemeProvider.colors.default,*/}
          {/*    display: 'table',*/}
          {/*    padding: '0px 10px',*/}
          {/*    marginLeft: '-10px'*/}
          {/*  }}>*/}
          {/*  Por R$ 99,90*/}
          {/*</Poppins>*/}
          {/*<Spacer size={2}/>*/}
          <Roboto
            size={'18px'}
            color={'#ffffff'}>
            Além do relatório PPA DISC e a Devolutiva Especializada, você também receberá os seguintes relatórios:
          </Roboto>
          <Spacer size={2}/>
          <Grid direction={"row"} style={{display: "flex"}}>
            <div style={{...styles.iconContainer}}>
              <FeedbackIcon style={{color: '#e27983', fontSize: '40px'}}/>
            </div>
            <Spacer vertical={true}/>
            <Grid style={{display: "flex", flex: 1}} direction={"column"}>
              <LightTooltip
                hideInfoIcon={true}
                title={
                  <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                    O relatório complementar <b>Feedback Pessoal </b> descreve através de palavras descritivas e
                    características gerais, quais são seus principais traços comportamentais percebidos, além de
                    observar quais são seus atuais motivadores e valores que possam contribuir para uma organização.
                  </Roboto>
                }>
                <Poppins
                  size={'20px'}
                  fontWeight={"bold"}
                  color={'#ffffff'}>
                  Feedback Pessoal <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
                </Poppins>
              </LightTooltip>
              <Spacer size={0.5}/>
              <Box display={'flex'} alignItems={'center'}>
                <Poppins
                  size={'15px'}
                  color={'#ffffff'}>
                  Fazer download do modelo
                </Poppins>
                <Spacer size={1} vertical={true}/>
                <a href={require('../../assets/documents/Feedback Pessoal.pdf')} target={'_blank'}>
                  <PictureAsPdfIcon style={{color: '#ffffff', fontSize: '30px'}}/>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Spacer size={2}/>
          <Grid direction={"row"} style={{display: "flex"}}>
            <div style={{...styles.iconContainer}}>
              <RateReviewIcon style={{color: '#e27983', fontSize: '40px'}}/>
            </div>
            <Spacer vertical={true}/>
            <Grid style={{display: "flex", flex: 1}} direction={"column"}>
              <LightTooltip
                hideInfoIcon={true}
                title={
                  <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                    O relatório complementar <b>Revisão Pessoal</b> descreve através de um objetivo texto, de palavras
                    descritivas e características gerais, como é seu estilo comportamental pessoal atual. O material
                    também contempla informações de como maximizar seu potencial e quais responsabilidades são mais
                    adequadas/apropriadas ao seu perfil.
                  </Roboto>
                }>
                <Poppins
                  size={'20px'}
                  fontWeight={"bold"}
                  color={'#ffffff'}>
                  Revisão Pessoal <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
                </Poppins>
              </LightTooltip>
              <Spacer size={0.5}/>
              <Box display={'flex'} alignItems={'center'}>
                <Poppins
                  size={'15px'}
                  color={'#ffffff'}>
                  Fazer download do modelo
                </Poppins>
                <Spacer size={1} vertical={true}/>
                <a href={require('../../assets/documents/Revisão Pessoal.pdf')} target={'_blank'}>
                  <PictureAsPdfIcon style={{color: '#ffffff', fontSize: '30px'}}/>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Spacer size={2}/>
          <Grid direction={"row"} style={{display: "flex"}}>
            <div style={{...styles.iconContainer}}>
              <LocalOfferIcon style={{color: '#e27983', fontSize: '40px'}}/>
            </div>
            <Spacer vertical={true}/>
            <Grid style={{display: "flex", flex: 1}} direction={"column"}>
              <LightTooltip
                hideInfoIcon={true}
                title={
                  <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                    O relatório complementar <b>Habilidades de Vendas</b> descreve, a partir do perfil PPA DISC, como é
                    o seu estilo de abordagem e comunicação em vendas, seu comportamento para fechamento de negócios,
                    como procura apresentar seus produtos e/ou serviços, como administra seu dia a dia e como pode
                    melhor explorar seu potencial de desenvolvimento.
                  </Roboto>
                }>
                <Poppins
                  size={'20px'}
                  fontWeight={"bold"}
                  color={'#ffffff'}>
                  Habilidades de Vendas <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
                </Poppins>
              </LightTooltip>
              <Spacer size={0.5}/>
              <Box display={'flex'} alignItems={'center'}>
                <Poppins
                  size={'15px'}
                  color={'#ffffff'}>
                  Fazer download do modelo
                </Poppins>
                <Spacer size={1} vertical={true}/>
                <a href={require('../../assets/documents/Habilidades de Vendas.pdf')} target={'_blank'}>
                  <PictureAsPdfIcon style={{color: '#ffffff', fontSize: '30px'}}/>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Spacer size={2}/>
          <Grid direction={"row"} style={{display: "flex"}}>
            <div style={{...styles.iconContainer}}>
              <PoolIcon style={{color: '#e27983', fontSize: '40px'}}/>
            </div>
            <Spacer vertical={true}/>
            <Grid style={{display: "flex", flex: 1}} direction={"column"}>
              <LightTooltip
                hideInfoIcon={true}
                title={
                  <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                    O relatório complementar <b>Habilidades de Liderança</b> descreve, a partir do perfil PPA DISC, como
                    é seu estilo de liderança envolvendo pontos fortes e limitações, características de comunicação,
                    apresentação e tomadas de decisão, como gerencia o tempo e cumpre prazos, como entende as críticas e
                    resolve problemas, além de entender como costuma dar foco, planejar e administrar seus objetivos. O
                    relatório também possui informações sobre suas necessidades de desenvolvimento para aumentar a
                    eficácia na delegação de tarefas e entrega de resultados.
                  </Roboto>
                }>
                <Poppins
                  size={'20px'}
                  fontWeight={"bold"}
                  color={'#ffffff'}>
                  Habilidades de Liderança <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
                </Poppins>
              </LightTooltip>
              <Spacer size={0.5}/>
              <Box display={'flex'} alignItems={'center'}>
                <Poppins
                  size={'15px'}
                  color={'#ffffff'}>
                  Fazer download do modelo
                </Poppins>
                <Spacer size={1} vertical={true}/>
                <a href={require('../../assets/documents/Habilidades de liderança.pdf')} target={'_blank'}>
                  <PictureAsPdfIcon style={{color: '#ffffff', fontSize: '30px'}}/>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Spacer size={2}/>
          <Grid direction={"row"} style={{display: "flex"}}>
            <div style={{...styles.iconContainer}}>
              <TrendingUpIcon style={{color: '#e27983', fontSize: '40px'}}/>
            </div>
            <Spacer vertical={true}/>
            <Grid style={{display: "flex", flex: 1}} direction={"column"}>
              <LightTooltip
                hideInfoIcon={true}
                title={
                  <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                    O relatório complementar <b>Pontos Fortes e Limitações</b> descreve, a partir do perfil PPA DISC,
                    quais são seus principais pontos fortes, apresentados em forma de tópicos claros e objetivos, bem
                    como seus possíveis pontos limitantes, fazendo com que ambos sirvam de reflexão para uma analogia ao
                    seu dia a dia de trabalho.
                  </Roboto>
                }>
                <Poppins
                  size={'20px'}
                  fontWeight={"bold"}
                  color={'#ffffff'}>
                  Pontos Fortes e Limitações <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
                </Poppins>
              </LightTooltip>
              <Spacer size={0.5}/>
              <Box display={'flex'} alignItems={'center'}>
                <Poppins
                  size={'15px'}
                  color={'#ffffff'}>
                  Fazer download do modelo
                </Poppins>
                <Spacer size={1} vertical={true}/>
                <a href={require('../../assets/documents/Pontos fortes e limitações.pdf')} target={'_blank'}>
                  <PictureAsPdfIcon style={{color: '#ffffff', fontSize: '30px'}}/>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Spacer size={2}/>
          <Grid direction={"row"} style={{display: "flex"}}>
            <div style={{...styles.iconContainer}}>
              <LocalActivityIcon style={{color: '#e27983', fontSize: '40px'}}/>
            </div>
            <Spacer vertical={true}/>
            <Grid style={{display: "flex", flex: 1}} direction={"column"}>
              <LightTooltip
                hideInfoIcon={true}
                title={
                  <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                    O relatório complementar <b>Necessidades de Treinamento</b> descreve, a partir do perfil PPA DISC,
                    quais são suas características mais aparentes e observáveis que podem ser desenvolvidas ou melhor
                    exploradas, visando sua evolução pessoal e profissional. O material se divide em como alcançar os
                    resultados efetivos, automotivação e recomendação de necessidades potenciais de treinamento.
                  </Roboto>
                }>
                <Poppins
                  size={'20px'}
                  fontWeight={"bold"}
                  color={'#ffffff'}>
                  Necessidades de Treinamento <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
                </Poppins>
              </LightTooltip>
              <Spacer size={0.5}/>
              <Box display={'flex'} alignItems={'center'}>
                <Poppins
                  size={'15px'}
                  color={'#ffffff'}>
                  Fazer download do modelo
                </Poppins>
                <Spacer size={1} vertical={true}/>
                <a href={require('../../assets/documents/Necessidades de treinamento.pdf')} target={'_blank'}>
                  <PictureAsPdfIcon style={{color: '#ffffff', fontSize: '30px'}}/>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Spacer size={2}/>
          <Grid direction={"row"} style={{display: "flex"}}>
            <div style={{...styles.iconContainer}}>
              <FeaturedPlayListIcon style={{color: '#e27983', fontSize: '40px'}}/>
            </div>
            <Spacer vertical={true}/>
            <Grid style={{display: "flex", flex: 1}} direction={"column"}>

              <LightTooltip
                hideInfoIcon={true}
                title={
                  <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                    O relatório complementar <b>Habilidades Administrativas</b> descreve, a partir do perfil PPA DISC,
                    quais são suas características de trabalho na esfera administrativa, envolvendo organização de
                    fluxos e tarefas, cumprimento de prazos e gerenciamento do tempo, como atende às necessidades de
                    informação e serviços, de que forma busca atingir precisão e qualidade no trabalho e como tende a
                    compreender e solucionar problemas.
                  </Roboto>
                }>
                <Poppins
                  size={'20px'}
                  fontWeight={"bold"}
                  color={'#ffffff'}>
                  Habilidades Administrativas <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
                </Poppins>
              </LightTooltip>


              <Spacer size={0.5}/>
              <Box display={'flex'} alignItems={'center'}>
                <Poppins
                  size={'15px'}
                  color={'#ffffff'}>

                  Fazer download do modelo
                </Poppins>
                <Spacer size={1} vertical={true}/>
                <a href={require('../../assets/documents/Habilidades administrativas.pdf')} target={'_blank'}>
                  <PictureAsPdfIcon style={{color: '#ffffff', fontSize: '30px'}}/>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Spacer size={2}/>
          <Grid direction={"row"} style={{display: "flex"}}>
            <div style={{...styles.iconContainer}}>
              <HomeWorkIcon style={{color: '#e27983', fontSize: '40px'}}/>
            </div>
            <Spacer vertical={true}/>
            <Grid style={{display: "flex", flex: 1}} direction={"column"}>

              <LightTooltip
                hideInfoIcon={true}
                title={
                  <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                    O relatório complementar <b>Gestão de Profissionais à Distância</b> descreve, a partir do perfil PPA
                    DISC, quais são suas preferências comportamentais num modelo de trabalho remoto, qual seu estilo
                    observável de comunicação, como tende a se motivar, quais são suas predileções de gerenciamento e
                    como costuma lidar com possíveis mudanças.
                  </Roboto>
                }>
                <Poppins
                  size={'20px'}
                  fontWeight={"bold"}
                  color={'#ffffff'}>
                  Gestão de Profissionais à Distância <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
                </Poppins>
              </LightTooltip>


              <Spacer size={0.5}/>
              <Box display={'flex'} alignItems={'center'}>
                <Poppins
                  size={'15px'}
                  color={'#ffffff'}>

                  Fazer download do modelo
                </Poppins>
                <Spacer size={1} vertical={true}/>
                <a href={require('../../assets/documents/Gestão dos Profissionais a distância.pdf')} target={'_blank'}>
                  <PictureAsPdfIcon style={{color: '#ffffff', fontSize: '30px'}}/>
                </a>
              </Box>
            </Grid>
          </Grid>
          <Spacer size={5}/>
        </TheColumnsLayout.LeftColumnContent>
        <TheColumnsLayout.RightColumnContent>
          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            Escolha seus relatórios
          </Poppins>
          <Spacer/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            Compre seu primeiro relatório por <b>R$ 49,90</b> e a partir do segundo relatório, pague apenas&nbsp;
            <b>R$ 19,90</b> cada um!
          </Roboto>
          <Spacer size={2}/>
          <TableContainer component={Paper} style={{border: '1px solid #e2e2e2'}}>
            <Table aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Roboto size={'16px'} fontWeight={"bold"}>
                      Pacote Completo
                    </Roboto>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'} fontWeight={"bold"}>
                      Preço (R$)
                    </Roboto>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.reports.some(a => a.reportTypeId == 111) && <TableRow>
                  <TableCell style={{width: '20px', padding: '0 0 0 10px'}}>
                    <Checkbox
                      color="primary" inputProps={{'aria-label': 'secondary checkbox'}}
                      checked={this.state.reportsSelected.some(a => a === 111)}
                      onChange={async () => {
                        await this._reportOnSelect(111);
                      }}/>
                  </TableCell>
                  <TableCell>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório <b>PPA DISC</b> (Análise de Perfil Pessoal) descreve a partir da teoria DISC, o
                          perfil
                          comportamental de um indivíduo, fornecendo informações acerca de suas características pessoais
                          e profissionais percebidas, fortalezas, automotivação, estilo de comunicação, funções mais
                          adequadas, ambiente ideal, tendência de comportamento em situação de pressão, aspectos
                          referentes a possíveis frustrações, stress, desmotivação e suas prováveis causas. De forma
                          ordenada, seu relatório está assim estruturado: <br/><br/>
                          - Gráfico DISC de Autoimagem e sua descrição<br/>
                          - Automotivação<br/>
                          - Ênfase no Trabalho<br/>
                          - Palavras Descritivas<br/>
                          - Gráfico DISC de Comportamento no Trabalho e sua descrição<br/>
                          - Gráfico DISC de Comportamento Sob Pressão e sua descrição<br/>
                          - Estímulos e Características Gerais<br/>
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        {this.state.reports?.filter(a => a.reportTypeId == 111)[0]?.name}
                        <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          <b>Devolutiva Individual:</b> Sessão agendada de 45 minutos com um especialista DISC da Thomas
                          International, realizada através de vídeo chamada!
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        + Devolutiva especializada<InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                    <Roboto size={'16px'}>
                      + Todos relatórios específicos inclusos
                    </Roboto>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto style={{textDecoration: 'line-through'}} size={'16px'}>
                      De 799,90
                       </Roboto>
                    <Roboto size={'16px'}>
                      Por {(this.state.reports?.filter(a => a.reportTypeId == 111)[0]?.priceAmount / 100).toFixed(2).replace('.', ',')}
                    </Roboto>
                  </TableCell>
                </TableRow>}

                {this.state.reports.some(a => a.reportTypeId != 111) && <TableRow>
                  <TableCell colSpan={2}>
                    <Roboto size={'16px'} fontWeight={"bold"}>
                      Relatórios
                    </Roboto>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'}>
                    </Roboto>
                  </TableCell>
                </TableRow>}

                {this.state.reports.some(a => a.reportTypeId == 113) && <TableRow>
                  <TableCell style={{width: '20px', padding: '0 0 0 10px'}}>
                    <Checkbox
                      color="primary" inputProps={{'aria-label': 'secondary checkbox'}}
                      checked={this.state.reportsSelected.some(a => a === 113)}
                      onChange={async () => {
                        await this._reportOnSelect(113);
                      }}/>
                  </TableCell>
                  <TableCell style={{display: 'flex', flexDirection: 'row'}}>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório complementar <b>Feedback Pessoal </b> descreve através de palavras descritivas e
                          características gerais, quais são seus principais traços comportamentais percebidos, além de
                          observar quais são seus atuais motivadores e valores que possam contribuir para uma
                          organização.
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        Feedback Pessoal <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório complementar <b>Revisão Pessoal</b> descreve através de um objetivo texto, de
                          palavras descritivas e características gerais, como é seu estilo comportamental pessoal atual.
                          O material também contempla informações de como maximizar seu potencial e quais
                          responsabilidades são mais adequadas/apropriadas ao seu perfil.
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        &nbsp;+ Relatório bônus de Revisão Pessoal <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'}>
                      {(this._getReportPrice(113) / 100).toFixed(2).replace('.', ',')}
                    </Roboto>
                  </TableCell>
                </TableRow>}
                {this.state.reports.some(a => a.reportTypeId == 106) && <TableRow>
                  <TableCell style={{width: '20px', padding: '0 0 0 10px'}}>
                    <Checkbox
                      color="primary" inputProps={{'aria-label': 'secondary checkbox'}}
                      checked={this.state.reportsSelected.some(a => a === 106)}
                      onChange={async () => {
                        await this._reportOnSelect(106);
                      }}/>
                  </TableCell>
                  <TableCell>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório complementar <b>Habilidades de Vendas</b> descreve, a partir do perfil PPA DISC,
                          como é o seu estilo de abordagem e comunicação em vendas, seu comportamento para fechamento de
                          negócios, como procura apresentar seus produtos e/ou serviços, como administra seu dia a dia e
                          como pode melhor explorar seu potencial de desenvolvimento.
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        Habilidades de Vendas <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'}>
                      {(this._getReportPrice(106) / 100).toFixed(2).replace('.', ',')}
                    </Roboto>
                  </TableCell>
                </TableRow>}
                {this.state.reports.some(a => a.reportTypeId == 103) && <TableRow>
                  <TableCell style={{width: '20px', padding: '0 0 0 10px'}}>
                    <Checkbox
                      color="primary" inputProps={{'aria-label': 'secondary checkbox'}}
                      checked={this.state.reportsSelected.some(a => a === 103)}
                      onChange={async () => {
                        await this._reportOnSelect(103);
                      }}/>
                  </TableCell>
                  <TableCell>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório complementar <b>Habilidades de Liderança</b> descreve, a partir do perfil PPA
                          DISC, como é seu estilo de liderança envolvendo pontos fortes e limitações, características de
                          comunicação, apresentação e tomadas de decisão, como gerencia o tempo e cumpre prazos, como
                          entende as críticas e resolve problemas, além de entender como costuma dar foco, planejar e
                          administrar seus objetivos. O relatório também possui informações sobre suas necessidades de
                          desenvolvimento para aumentar a eficácia na delegação de tarefas e entrega de resultados.
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        Habilidades de Liderança <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'}>
                      {(this._getReportPrice(103) / 100).toFixed(2).replace('.', ',')}
                    </Roboto>
                  </TableCell>
                </TableRow>}
                {this.state.reports.some(a => a.reportTypeId == 105) && <TableRow>
                  <TableCell style={{width: '20px', padding: '0 0 0 10px'}}>
                    <Checkbox
                      color="primary"
                      inputProps={{'aria-label': 'secondary checkbox'}}
                      checked={this.state.reportsSelected.some(a => a === 105)}
                      onChange={async () => {
                        await this._reportOnSelect(105);
                      }}/>
                  </TableCell>
                  <TableCell>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório complementar <b>Pontos Fortes e Limitações</b> descreve, a partir do perfil PPA
                          DISC, quais são seus principais pontos fortes, apresentados em forma de tópicos claros e
                          objetivos, bem como seus possíveis pontos limitantes, fazendo com que ambos sirvam de reflexão
                          para uma analogia ao seu dia a dia de trabalho.
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        Pontos Fortes e Limitações <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'}>
                      {(this._getReportPrice(105) / 100).toFixed(2).replace('.', ',')}
                    </Roboto>
                  </TableCell>
                </TableRow>}
                {this.state.reports.some(a => a.reportTypeId == 107) && <TableRow>
                  <TableCell style={{width: '20px', padding: '0 0 0 10px'}}>
                    <Checkbox
                      color="primary"
                      inputProps={{'aria-label': 'secondary checkbox'}}
                      checked={this.state.reportsSelected.some(a => a === 107)}
                      onChange={async () => {
                        await this._reportOnSelect(107);
                      }}/>
                  </TableCell>
                  <TableCell>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório complementar <b>Necessidades de Treinamento</b> descreve, a partir do perfil PPA
                          DISC, quais são suas características mais aparentes e observáveis que podem ser desenvolvidas
                          ou melhor exploradas, visando sua evolução pessoal e profissional. O material se divide em
                          como alcançar os resultados efetivos, automotivação e recomendação de necessidades potenciais
                          de treinamento.
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        Necessidades de Treinamento <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'}>
                      {(this._getReportPrice(107) / 100).toFixed(2).replace('.', ',')}
                    </Roboto>
                  </TableCell>
                </TableRow>}
                {this.state.reports.some(a => a.reportTypeId == 108) && <TableRow>
                  <TableCell style={{width: '20px', padding: '0 0 0 10px'}}>
                    <Checkbox
                      color="primary"
                      inputProps={{'aria-label': 'secondary checkbox'}}
                      checked={this.state.reportsSelected.some(a => a === 108)}
                      onChange={async () => {
                        await this._reportOnSelect(108);
                      }}/>
                  </TableCell>
                  <TableCell>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório complementar <b>Habilidades Administrativas</b> descreve, a partir do perfil PPA
                          DISC, quais são suas características de trabalho na esfera administrativa, envolvendo
                          organização de fluxos e tarefas, cumprimento de prazos e gerenciamento do tempo, como atende
                          às necessidades de informação e serviços, de que forma busca atingir precisão e qualidade no
                          trabalho e como tende a compreender e solucionar problemas.
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        Habilidades Administrativas <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'}>
                      {(this._getReportPrice(108) / 100).toFixed(2).replace('.', ',')}
                    </Roboto>
                  </TableCell>
                </TableRow>}
                {this.state.reports.some(a => a.reportTypeId == 102) && <TableRow>
                  <TableCell style={{width: '20px', padding: '0 0 0 10px'}}>
                    <Checkbox
                      color="primary"
                      inputProps={{'aria-label': 'secondary checkbox'}}
                      checked={this.state.reportsSelected.some(a => a === 102)}
                      onChange={async () => {
                        await this._reportOnSelect(102);
                      }}/>
                  </TableCell>
                  <TableCell>
                    <LightTooltip
                      hideInfoIcon={true}
                      title={
                        <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}>
                          O relatório complementar <b>Gestão de Profissionais à Distância</b> descreve, a partir do
                          perfil PPA DISC, quais são suas preferências comportamentais num modelo de trabalho remoto,
                          qual seu estilo observável de comunicação, como tende a se motivar, quais são suas predileções
                          de gerenciamento e como costuma lidar com possíveis mudanças.
                        </Roboto>
                      }>
                      <Roboto size={'16px'}>
                        Gestão de Profissionais à Distância <InfoIcon style={{color: '#9c9c9c', fontSize: '16px'}}/>
                      </Roboto>
                    </LightTooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'}>
                      {(this._getReportPrice(102) / 100).toFixed(2).replace('.', ',')}
                    </Roboto>
                  </TableCell>
                </TableRow>}

                <TableRow>

                  <TableCell colSpan={2} align="right">

                    <Roboto size={'16px'} fontWeight={"bold"}>
                      Total
                    </Roboto>

                  </TableCell>
                  <TableCell align="right">
                    <Roboto size={'16px'} fontWeight={"bold"}>
                      {this._totalPriceOnChange()}
                    </Roboto>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Spacer size={5}/>
          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            Pagamento
          </Poppins>
          <Spacer size={2}/>
          <Grid item xs={12}>
            <form>
              <TextField
                ref={component => (this._refCreditCardNumber = component)}
                label="Numero do Cartão"
                mask={"creditCardNumber"}
                required={true}
                value={this.state.creditCardNumber}
                onChangeText={(creditCardNumber: string) => this.setState({creditCardNumber})}
              />
              <Spacer/>
              <TextField
                ref={component => (this._refCreditCardHolder = component)}
                label="Nome do titular"
                required={true}
                value={this.state.creditCardHolder}
                onChangeText={(creditCardHolder: string) => this.setState({creditCardHolder})}
              />
              <Spacer/>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    ref={component => (this._refCreditCardExpiry = component)}
                    label="Validade"
                    mask={"creditCardExpiry"}
                    required={true}
                    value={this.state.creditCardExpiry}
                    onChangeText={(creditCardExpiry: string) => this.setState({creditCardExpiry})}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    ref={component => (this._refCreditCardCVV = component)}
                    label="CVV"
                    mask={"number"}
                    maxLength={4}
                    required={true}
                    value={this.state.creditCardCVV}
                    onChangeText={(creditCardCVV: string) => this.setState({creditCardCVV})}
                  />
                </Grid>
              </Grid>
              <Spacer/>
              <TextField
                ref={component => (this._refCreditCardHolderDocument = component)}
                label="CPF do Titular"
                mask={"cpf"}
                validate={"cpf"}
                required={true}
                value={this.state.creditCardHolderDocument}
                onChangeText={(creditCardHolderDocument: string) => this.setState({creditCardHolderDocument})}
              />
              <Spacer size={2}/>
              <Button variant="contained" color={"secondary"} style={styles.button} disableElevation
                      onClick={this._paymentOnPress}>
                Concluir compra
              </Button>
              <Spacer/>
              <Button variant="contained" color={"inherit"} style={{...styles.button, color: 'white'}} disableElevation
                      onClick={() => {
                        window.location.href = '/resultado/' + this.candidateGuid
                      }}>
                Voltar
              </Button>
            </form>
          </Grid>
          <Spacer size={5}/>
          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            Importante saber
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            ● &nbsp; Ao concluir sua compra, seu relatório será enviado imediatamente ao seu e-mail cadastrado. Caso não
            encontre em sua caixa de entrada, procure em sua caixa de spam
          </Roboto>
          <Spacer size={1}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            ● &nbsp; Você terá acesso aos seus relatórios também pelo login de acesso, através do link
            <a href="https://meuppa.com/login"> https://meuppa.com/login</a>
          </Roboto>
          <Spacer size={1}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            ● &nbsp; Caso precise de apoio ou tenha dúvidas, acesse nosso FAQ ou envie um e-mail para
            meuppa@thomasbrasil.com.br
          </Roboto>
          <Spacer size={2}/>
        </TheColumnsLayout.RightColumnContent>
        <Loading loading={this.state.loading}/>
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          open={this.state.dialogShow}
          onClose={() => {
            this.setState({dialogShow: false})
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.dialogType === 'payment-error' && <Button onClick={() => {
              this.setState({dialogShow: false})
            }} color="primary" autoFocus>
              Ok
            </Button>}
            {this.state.dialogType === 'payment-success' && <Button onClick={() => {
              this.setState({dialogShow: false});
              window.location.href = '/resultado/' + this.candidateGuid;
            }} color="primary" autoFocus>
              Ok, voltar a página de resultado
            </Button>}
          </DialogActions>
        </Dialog>
      </TheColumnsLayout>
    );
  }

  _reportOnSelect = async (reportId: number) => {
    let reportsSelected = this.state.reportsSelected;
    let index = reportsSelected.findIndex(x => x == reportId);
    if (index > -1) {
      reportsSelected.splice(index, 1);
      if (reportId !== 111) {
        let indexFullProfile = reportsSelected.findIndex(x => x == 111);
        if (indexFullProfile > -1) {
          reportsSelected.splice(indexFullProfile, 1);
        }
      }
    } else {
      reportsSelected.push(reportId);
      if (reportId === 111) {
        reportsSelected = [];
        reportsSelected.push(111);
        reportsSelected.push(113);
        reportsSelected.push(106);
        reportsSelected.push(103);
        reportsSelected.push(105);
        reportsSelected.push(107);
        reportsSelected.push(108);
        reportsSelected.push(102);
      }
    }

    await this.setState({reportsSelected})
  }

  _paymentOnPress = async () => {
    await this.setState({loading: true});

    if (await TextField.fieldsValid([this._refCreditCardNumber, this._refCreditCardHolder, this._refCreditCardExpiry, this._refCreditCardCVV, this._refCreditCardHolderDocument])) {
      let expDateSplit = this.state.creditCardExpiry.split('/');
      let productIds = [];
      for (let reportId of this.state.reportsSelected) {
        let report = this.state.reports.find(a => a.reportTypeId === reportId);
        if (!!report) {
          productIds.push(report.productId);
        }
      }

      await AssessmentService.purchaseReports(this.state.assessmentGuid,
        {
          "creditCard": {
            "cardNumber": this.state.creditCardNumber,
            "holder": this.state.creditCardHolder,
            "expirationDate": expDateSplit[0] + '/20' + expDateSplit[1],
            "securityCode": this.state.creditCardCVV,
            "document": this.state.creditCardHolderDocument
          },
          "productIds": productIds
        }
      ).then(data => {
        sessionStorage.setItem("payment-success", JSON.stringify(true));
        window.location.href = '/resultado/' + this.candidateGuid;
        // this.setState({
        //   dialogTitle: 'Pagamento ✔',
        //   dialogMessage: 'Pagamento processado com sucesso, em alguns minutos você irá receber um e-mail com o(s) relatório(s).',
        //   dialogShow: true,
        //   dialogType: "payment-success"
        // });
      }).catch((err: Error) => {
        this.setState({
          dialogTitle: 'Pagamento ❌',
          dialogMessage: 'O pagamento não pode ser concluído, por favor verifique os dados informados.',
          dialogShow: true,
          dialogType: "payment-error"
        });
      });
    }

    await this.setState({loading: false});
  }

  _totalPriceOnChange = (): string => {
    let total = 0;
    let reportsSelected = this.state.reportsSelected;
    if (reportsSelected.some(a => a === 111)) {
      total = (this.state.reports?.filter(a => a.reportTypeId == 111)[0]?.priceAmount);
    } else {
      for (let i = 0; i < reportsSelected.length; i++) {
        let reportId = reportsSelected[i];
        total += this._getReportPrice(reportId);
      }
    }
    return (total / 100).toFixed(2).replace('.', ',');
  }

  _getReportPrice = (reportId: number): number => {
    let discount = 0;
    let reportsSelected = this.state.reportsSelected;
    if (reportsSelected.some(a => a === 111)) return 0;
    let reportPrice = this.state.reports?.filter(a => a.reportTypeId == reportId)[0]?.priceAmount;
    let reportsSelectedCount = reportsSelected.filter(a => a !== 111).length;
    if (reportsSelectedCount > 0) {
      discount = reportsSelected[0] === reportId ? 0 : reportPrice - (reportPrice * 0.3987975952);
    }
    return reportPrice - discount;
  }
}

const styles = StyleSheet.create({
  input: {
    width: '100%'
  },
  button: {
    width: '100%',
    padding: '10px',
    minHeight: '56px'
  },
  iconContainer: {
    width: '80px',
    height: '80px',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  }
});
