import axios, {AxiosRequestConfig, ResponseType} from 'axios';
import AppSetting from "../settings/AppSetting";
import {AuthService} from "../services/AuthService";


export type AxiosMiddlewareConfig = {
  baseURL?: string;
  headers?: any;
  timeout?: number;
  responseType?: ResponseType;
  onUploadProgress?: (progressEvent: any) => void;
  onDownloadProgress?: (progressEvent: any) => void;
  maxContentLength?: number;
  maxRedirects?: number;
}

export class AxiosMiddleware {
  private static client: any;

  private static async setClient() {
    let auth = await AuthService.authenticate();

    this.client = await axios.create({
      baseURL: AppSetting.baseUrl,
      headers: {
        "Authorization": `bearer ${auth['access_token']}`
      }
    });
  }

  private static handleCatchData(data: any): Promise<any> {
    let resp = data.response;
    if (resp.status === 401) {
      console.error("Unauthorized");
    }
    throw resp.data;
  }

  static async delete(url: string, config?: AxiosRequestConfig) {
    await this.setClient();
    return await this.client.delete(url, config)
      .then((data: any) => {
        return data.data;
      })
      .catch((data: any) => {
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  static async head(url: string, config?: AxiosRequestConfig) {
    await this.setClient();
    return await this.client.head(url, config)
      .then((data: any) => {
        return data.data;
      })
      .catch((data: any) => {
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  static async put(url: string, data?: any, config?: AxiosRequestConfig) {
    await this.setClient();
    return await this.client.put(url, data, config)
      .then((data: any) => {
        return data.data;
      })
      .catch((data: any) => {
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  static async patch(url: string, data?: any, config?: AxiosRequestConfig) {
    await this.setClient();
    return await this.client.patch(url, data, config)
      .then((data: any) => {
        return data.data;
      })
      .catch((data: any) => {
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  static async get(url: string, config?: AxiosRequestConfig) {
    await this.setClient();
    return await this.client.get(url, config)
      .then((data: any) => {
        return data.data;
      })
      .catch((data: any) => {
        return AxiosMiddleware.handleCatchData(data);
      });
  }

  static async post(url: string, data?: any, config?: AxiosRequestConfig) {
    await this.setClient();
    return await this.client.post(url, data, config)
      .then((data: any) => {
        return data.data;
      })
      .catch((data: any) => {
        return AxiosMiddleware.handleCatchData(data);
      });
  }

}
