import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {Button, ClickAwayListener} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';

export type Props = {
  title: NonNullable<React.ReactNode>
  children?: any
  hideInfoIcon?: boolean
}

const LightTooltipComp = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[10],
    fontSize: 11,
    padding: 15
  },
}))(Tooltip);

export function LightTooltip(props: Props) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ClickAwayListener onClickAway={() => {
      setOpen(false)
    }}>
      <LightTooltipComp title={props.title} open={open}>
        <div
          onMouseOver={() => {
            setOpen(true)
          }}
          onMouseOut={() => {
            setOpen(false)
          }}>
          {!props.hideInfoIcon && <div style={{float: 'right', paddingLeft: 5}}>
            <Button style={{display: 'contents'}} onClick={() => {
              setOpen(true)
            }}>
              <InfoIcon style={{color: '#ffffff', fontSize: '20px'}}/>
            </Button>
          </div>}
          {props.children}
        </div>
      </LightTooltipComp>
    </ClickAwayListener>
  );
}
