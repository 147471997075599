import React from "react";
import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";
import moment from "moment";

import {TextField as MaterialTextField} from "@material-ui/core";

export type Props = {
  label?: string,
  style?: any,
  secureTextEntry?: boolean,
  value?: string,
  error?: boolean,
  onChangeText: Function,
  onPress?: Function,
  editable?: boolean,
  multiline?: boolean,
  autoCapitalize?: 'sentences' | 'none' | 'words' | 'characters',
  mask?: 'phone' | 'date' | 'number' | 'cpf' | 'creditCardNumber' | 'creditCardExpiry',
  maxLength?: number,
  validate?: 'email' | 'birth-date' | 'cpf' | 'phone',
  required?: boolean,
  confirmInput?: TextField
}

export type States = {
  showPassword: boolean,
  error: boolean,
  placeholder: string
}

export class TextField extends React.Component<Props, States> {
  static defaultProps = {
    returnKeyType: "done",
    showPassword: false,
    editable: true,
    multiline: false
  };

  masks = {
    date: (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})(\d{1,2})?(\d{1,4})?/, '$1/$2/$3').replace(/\D+$/, '').slice(0, 10),
    phone: (value: string) => new AsYouType('BR').input((value)),
    number: (value: string) => (value || '').replace(/\D+/g, ''),
    cpf: (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{3})(\d{1,3})(\d{1,3})?(\d{1,2})?/, '$1.$2.$3-$4').replace(/\D+$/, '').slice(0, 14),
    creditCardNumber: (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{4})(\d{1,4})(\d{1,4})?(\d{1,4})?/, '$1 $2 $3 $4').replace(/\D+$/, '').slice(0, 19),
    creditCardExpiry: (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})(\d{1,2})?/, '$1/$2').replace(/\D+$/, '').slice(0, 5)
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showPassword: false,
      error: false,
      placeholder: ''
    };
  }

  _renderIconElement() {
    if (this.props.secureTextEntry) {
      return null;
    }
  }

  render() {
    let autoCapitalize = this.props.autoCapitalize;
    let inlineStyle = {};

    if (this.props.secureTextEntry) {
      autoCapitalize = 'none';
    }

    return (
      <MaterialTextField
        placeholder={this.state.placeholder}
        label={this.props.label}
        variant="outlined"
        fullWidth={true}
        disabled={!this.props.editable}
        error={this.state.error}
        value={this.props.value}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onChange={this.onChangeText}
      />
    )
  }

  onChangeText = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value = event.target.value;

    if (this.props.mask === "phone") {
      let tmpValue = this.masks.phone(value).replace('+', '');
      value = tmpValue ? '+' + tmpValue : '';
    } else if (this.props.mask === "date") {
      value = this.masks.date(value);
    } else if (this.props.mask === "number") {
      value = this.masks.number(value);
    } else if (this.props.mask === "cpf") {
      value = this.masks.cpf(value)
    } else if (this.props.mask === "creditCardNumber") {
      value = this.masks.creditCardNumber(value);
    } else if (this.props.mask === "creditCardExpiry") {
      value = this.masks.creditCardExpiry(value);
    }

    if (this.props.maxLength ?? 0 > 0) {
      value = value.substr(0, this.props.maxLength)
    }

    this.props.onChangeText(value);
  };

  onFocus = () => {
    this.setPlaceHolder();
    if (this.props.mask === "phone" && !this.props.value) {
      this.props.onChangeText('+55');
    }
  };

  onBlur = () => {
    let error = false;

    if (this.props.required) {
      error = !this.props.value
    }

    if (!error && !!this.props.value) {

      if (this.props.validate === "email") {
        error = !this.isEmail(this.props.value);
      } else if (this.props.validate === "birth-date") {
        error = !this.isPastDate(this.props.value, 'DD-MM-YYYY');
      } else if (this.props.validate === "cpf") {
        error = !this.isCPFValid(this.props.value)
      } else if (this.props.validate === "phone") {
        const phoneNumber = parsePhoneNumberFromString(this.props.value, 'BR');
        error = !phoneNumber?.isValid();
      }

      if (this.props.confirmInput) {
        error = error || this.props.confirmInput.props.value !== this.props.value;
      }
    }

    if (error) {
      this.setState({placeholder: ""});
    }

    this.setState({error});
  };

  setPlaceHolder = () => {
    if (this.props.mask === "creditCardExpiry") {
      this.setState({placeholder: '01/20'});
    }
  }

  isValid = async (): Promise<boolean> => {
    await this.onBlur();
    return !this.state.error;
  };

  private isEmail = (value: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,100}$/;
    return emailPattern.test(value);
  };

  private isDate = (dateStr: string, format: 'YYYY-MM-DD' | 'DD-MM-YYYY') => {
    let date = moment(dateStr, "DD/MM/YYYY", true);
    format.replace(/-/g, "/");
    const regex = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;
    return (date.isValid() && regex.test(date.format("DD/MM/YYYY")))
  };

  private isPastDate = (date: string, format: 'YYYY-MM-DD' | 'DD-MM-YYYY') => {
    if (!this.isDate(date, format)) {
      return false
    }
    const pastDate = moment(date, format);
    const today = moment();
    return moment(pastDate).isBefore(today)
  };

  private isCPFValid = (value: string) => {
    value = value.replace(/\D+/g, '');
    let sum;
    let rest;
    sum = 0;
    if (value === "00000000000") return false;
    for (let i = 1; i <= 9; i++) sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;
    if ((rest === 10) || (rest === 11)) rest = 0;
    if (rest !== parseInt(value.substring(9, 10))) return false;
    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;
    if ((rest === 10) || (rest === 11)) rest = 0;
    return rest === parseInt(value.substring(10, 11));
  };

  static async fieldsValid(textFields: (TextField | null | undefined)[]): Promise<boolean> {
    let isGroupValid: boolean = true;
    for (let textField of textFields) {
      if (!textField || !await textField.isValid()) {
        isGroupValid = false;
      }
    }
    return isGroupValid;
  }
}
