import React, {Component} from "react";
import {StyleSheet} from '../../styles/StyleSheet'
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "@material-ui/core";
import {Poppins, Roboto} from "../../components/StyledText";
import {TheColumnsLayout} from "../../layouts/the-columns/TheColumnsLayout";
import FaqData from '../../data/faq.json'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactMarkdown from "react-markdown";


export type Props = {};

export type States = {
  faqIdExpanded: number
};

export class FaqPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      faqIdExpanded: 1
    }
  }

  render() {
    let faqs = [];

    for (let faq of FaqData) {
      faqs.push(<ExpansionPanel
        elevation={0}
        expanded={this.state.faqIdExpanded === faq.id}
        onChange={() => {
          this.setState({faqIdExpanded: faq.id});
        }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1d-content"
          id="panel1d-header">
          <Roboto
            size={'20px'}
            fontWeight={"bold"}>
            {faq.title}
          </Roboto>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Roboto
            size={'18x'}
            fontWeight={"normal"}>
            <ReactMarkdown source={faq.description}/>
          </Roboto>
        </ExpansionPanelDetails>
      </ExpansionPanel>)
    }

    return (
      <TheColumnsLayout leftBackgroundColor={'#4eace5'}>
        <TheColumnsLayout.LeftColumnContent>
          <Poppins
            size={'40px'}
            fontWeight={"bold"}
            color={'#ffffff'}>
            Perguntas frequêntes
          </Poppins>
        </TheColumnsLayout.LeftColumnContent>
        <TheColumnsLayout.RightColumnContent>
          <div>
            {faqs}
          </div>
        </TheColumnsLayout.RightColumnContent>
      </TheColumnsLayout>
    );
  }


}

const styles = StyleSheet.create({});
