import React from 'react';


type  Props = {
  size?: number,
  vertical?: boolean
}

export class Spacer extends React.Component<Props> {
  render() {
    let size = 15 * (this.props.size ? this.props.size : 1);
    if (this.props.vertical) {
      return <div style={{width: `${size}px`}}/>
    } else {
      return <div style={{height: `${size}px`}}/>
    }
  }
}
