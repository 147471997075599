import React, {CSSProperties} from 'react';
import {ColorProperty, FontSizeProperty, FontWeightProperty} from "csstype";


export type Props = {
  size?: FontSizeProperty<string | 0>,
  color?: ColorProperty,
  fontWeight?: FontWeightProperty
  style?: CSSProperties
}

export class Poppins extends React.Component<Props> {
  render() {
    return <p
      style={{
        hyphens: 'auto',
        fontFamily: 'Poppins, sans-serif',
        fontSize: this.props.size,
        color: this.props.color,
        fontWeight: this.props.fontWeight,
        padding: 0,
        margin: 0,
        ...this.props.style
      }}>
      {this.props.children}
    </p>;
  }
}

export class Montserrat extends React.Component<Props> {
  render() {
    return <p
      style={{
        hyphens: 'auto',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: this.props.size,
        color: this.props.color,
        fontWeight: this.props.fontWeight,
        padding: 0,
        margin: 0,
        ...this.props.style
      }}>
      {this.props.children}
    </p>;
  }
}

export class Roboto extends React.Component<Props> {
  render() {
    return <p
      style={{
        fontFamily: 'Roboto, sans-serif',
        fontSize: this.props.size,
        color: this.props.color,
        fontWeight: this.props.fontWeight,
        padding: 0,
        margin: 0,
        ...this.props.style
      }}>
      {this.props.children}
    </p>;
  }
}
