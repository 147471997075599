import React, {Component} from "react";
import {StyleSheet} from '../../styles/StyleSheet'
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from "@material-ui/core";
import {Poppins, Roboto} from "../../components/StyledText";
import {Spacer} from "../../components/Spacer";
import {TheColumnsLayout} from "../../layouts/the-columns/TheColumnsLayout";
import {ThemeProvider} from "../../themes/ThemeProvider";
import {AssessmentService} from "../../services/AssessmentService";
import PPAProfileData from "../../data/ppa-disc-profile.json"
import {Loading} from "../../components/Loading";
import {LightTooltip} from "../../components/Tooltip";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import FeedbackIcon from '@material-ui/icons/Feedback';
import RateReviewIcon from '@material-ui/icons/RateReview';
import PoolIcon from "@material-ui/icons/Pool";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import HomeWorkIcon from "@material-ui/icons/HomeWork";

export type Props = {
  candidateGuid: string;
};

export type States = {
  ppaResult: string;
  candidateName: string;
  reports: any[];
  loading: boolean;

  dialogShow: boolean;
  dialogTitle: string;
  dialogMessage: string;
};

export class AssessmentResultPage extends Component<Props, States> {
  candidateGuid: string = window.location.href.split('/')[window.location.href.split('/').length - 1];

  constructor(props: Props) {
    super(props);
    this.state = {
      reports: [{}],
      ppaResult: 'NONE',
      candidateName: '',
      loading: true,

      dialogShow: false,
      dialogTitle: '',
      dialogMessage: '',
    }
  }


  async componentDidMount() {
    let data: any = await AssessmentService.getResult(this.candidateGuid);
    let reports: any = await AssessmentService.getReports(data.orderGuid);
    let intervalId = setInterval(async () => {
      let reportsReload: any = await AssessmentService.getReports(data.orderGuid);
      this.setState({reports: reportsReload});
      if (!this.state.reports.some(a => a.reportUrl == null)) {
        clearInterval(intervalId);
      }
    }, 5000);

    this.setState({reports: reports});
    this.setState({ppaResult: data.result});
    this.setState({candidateName: data.firstName});
    this.setState({loading: false});

    if (sessionStorage.getItem("payment-success")) {
      await this.setState({
        dialogTitle: 'Pagamento ✔',
        dialogMessage: 'Pagamento processado com sucesso, estamos gerando seus relatórios, eles irão aparcer nesta mesma página e também serão enviados para o seu e-mail.',
        dialogShow: true
      });
      sessionStorage.removeItem("payment-success")
    }

  }

  renderReportCard(reportName: string, reportTypeId: number, reportIcon: any) {
    if (!this.state.reports.some(a => a.reportTypeId == reportTypeId)) return null;
    return <Grid item xs={12} sm={4}>
      <Card style={{
        backgroundColor: this.state.reports.filter(a => a.reportTypeId == reportTypeId)[0].reportUrl == null ? "rgb(214 214 214)" : ThemeProvider.colors.primary,
        minHeight: 180
      }} variant="outlined">
        <CardContent>
          <Grid direction={"column"} style={{display: "flex", alignItems: 'center'}}>
            {reportIcon}
            <Spacer/>
            <Poppins
              style={{textAlign: 'center'}}
              color={"#FFFFFF"}
              size={'16px'}
              fontWeight={"bold"}>
              {reportName}
            </Poppins>
            <Spacer/>
            {this.state.reports.filter(a => a.reportTypeId == reportTypeId)[0].reportUrl != null &&
            <a href={this.state.reports.filter(a => a.reportTypeId == reportTypeId)[0].reportUrl} target={'_blank'}
               style={{textDecoration: 'none'}}>
              <Poppins
                color={"#FFFFFF"}
                fontWeight={'bold'}
                size={'14px'}>
                Download
              </Poppins>
            </a>}
            {this.state.reports.filter(a => a.reportTypeId == reportTypeId)[0].reportUrl == null &&
            <Poppins
              color={"#FFFFFF"}
              fontWeight={'bold'}
              size={'14px'}>
              Processando...
            </Poppins>}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  }

  render() {

    let result = PPAProfileData.find(a => a.disc == this.state.ppaResult);

    return (
      <TheColumnsLayout leftBackgroundColor={'#294352'}>
        <TheColumnsLayout.LeftColumnContent style={{textAlign: 'center'}}>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            Olá, {this.state.candidateName}!
          </Roboto>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            Muito obrigado por responder o teste comportamental <b><i>PPA DISC</i></b> da <b><i>Thomas International</i></b>!
          </Roboto>
          <Spacer size={2}/>
          <Poppins
            size={'25px'}
            fontWeight={"bold"}
            color={'#ffffff'}>
            Chamamos seu perfil DISC “{result?.disc}” de
          </Poppins>
          <Poppins
            size={'25px'}
            fontWeight={"bold"}
            color={'#ffffff'}
            style={{backgroundColor: ThemeProvider.colors.danger, display: 'inline', padding: '0px 15px'}}>
            {result?.profile.toUpperCase()}
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            As palavras que melhor descrevem seu perfil são:
          </Roboto>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            {result?.keywords}
          </Roboto>
          <Spacer size={2}/>
          {(result?.disc != 'NONE') &&
          <img src={require(`../../assets/images/ppa-profile/${result?.disc}.svg`)} alt="PPA DI"
               style={{maxHeight: '75vh'}}/>
          }
          <Spacer size={5}/>
        </TheColumnsLayout.LeftColumnContent>
        <TheColumnsLayout.RightColumnContent>
          {this.state.reports.some(a => !!a.reportTypeId) && <div>
            <Poppins
              size={'20px'}
              fontWeight={"bold"}>
              Relatórios adquiridos
            </Poppins>
            <Spacer size={2}/>
            <Grid container spacing={3}>
              {this.renderReportCard("PPA DISC Completo", 111, <AssessmentIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}
              {this.renderReportCard("Feedback Pessoal", 101, <FeedbackIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}
              {this.renderReportCard("Revisão Pessoal", 113, <RateReviewIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}
              {this.renderReportCard("Habilidades de Vendas", 106, <LocalOfferIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}
              {this.renderReportCard("Habilidades de Liderança", 103, <PoolIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}
              {this.renderReportCard("Pontos Fortes e Limitações", 105, <TrendingUpIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}
              {this.renderReportCard("Necessidades de Treinamento", 107, <LocalActivityIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}
              {this.renderReportCard("Habilidades Administrativas", 108, <FeaturedPlayListIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}
              {this.renderReportCard("Gestão de Profissionais à Distância", 102, <HomeWorkIcon
                style={{color: '#ffffff', fontSize: '40px'}}/>)}

              {this.state.reports.some(a => a.reportUrl == null) && <Roboto
                size={'14px'}
                fontWeight={"normal"}>
                <i>Estamos processando seus relatórios, isso pode levar até 1 hora, mas você pode fechar essa página,
                  pois enviaremos por e-mail assim que estiverem prontos.</i>
              </Roboto>}
            </Grid>
            <Spacer size={5}/>
          </div>}
          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            {this.state.reports.some(a => !!a.reportTypeId)
              ? `E aí ${this.state.candidateName}, identificou-se com os relatórios adquiridos?`
              : `E aí ${this.state.candidateName}, identificou-se com os adjetivos relacionados ao seu perfil DISC?`}
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            {this.state.reports.some(a => !!a.reportTypeId)
              ? `Caso queira adquirir os demais relatórios disponíveis, acesse o link abaixo.`
              : `Saiba que este conjunto de palavras explica a maioria de seus comportamentos e atitudes do dia a dia, além de mostrar suas preferências de comunicação e de relacionamento com outras pessoas.`}
          </Roboto>
          <Spacer size={2}/>
          <Button variant="contained" color={"secondary"} style={styles.button} disableElevation onClick={() => {
            window.location.href = '/compra/' + this.candidateGuid
          }}>
            {this.state.reports.some(a => !!a.reportTypeId) ? "Quero obter novos relatórios complementares" : "Quero obter meu relatório completo"}
          </Button>
          <Spacer size={5}/>
          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            Metodologia Comportamental DISC
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            Com o Relatório PPA DISC, você compreenderá:
          </Roboto>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            ● &nbsp; Suas principais características comportamentais através de uma identificação de autoimagem
          </Roboto>
          <Spacer size={1}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            ● &nbsp; Como é percebida sua relação atual com o trabalho ou em sua ocupação profissional
          </Roboto>
          <Spacer size={1}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            ● &nbsp; Como é seu estado comportamental em situações de estresse ou pressão
          </Roboto>
          <Spacer size={5}/>

          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            Conheça e destaque seus pontos fortes numa entrevista de emprego ou nas relações com seu chefe e colegas de
            trabalho!
          </Poppins>
          <Spacer size={2}/>

          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            Preparamos um vídeo especial para você entender porque a metodologia comportamental DISC é a mais usada por
            empresas no mundo todo para contratar e desenvolver pessoas!
          </Roboto>
          <Spacer size={2}/>
          <div style={{position: 'relative', paddingTop: '56.25%', overflow: 'hidden'}}>
            <iframe src="https://www.youtube.com/embed/R0FCw3fvTig" frameBorder="0" height="100%" width="100%"
                    allowFullScreen
                    style={{
                      border: 0,
                      height: '100%',
                      left: 0,
                      position: 'absolute',
                      top: 0,
                      width: '100%'
                    }}/>
          </div>
          <Spacer size={2}/>


          <Button variant="contained" color={"secondary"} style={styles.button} disableElevation onClick={() => {
            window.location.href = '/compra/' + this.candidateGuid
          }}>
            {this.state.reports.some(a => !!a.reportTypeId) ? "Acesse agora outros relatórios complementares" : "Acesse agora seu relatório comportamental PPA DISC completo"}
          </Button>
          <Spacer size={5}/>
          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            Histórico e Teoria
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            A metodologia DISC é um conceito acadêmico desenvolvido a partir de estudos sobre comportamentos observáveis
            feitos pelo psicólogo e pesquisador da Universidade de Harvard, William M. Marston.
          </Roboto>
          <Spacer/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            Através da combinação e intensidade dos 4 fatores definidos por Marston: <b>Dominância, Influência,
            Estabilidade e Conformidade</b>, tem-se a singularidade de cada indivíduo, percebida por meio de diversas
            características gerais, motivações pessoais e interações relacionais, sem que haja uma predileção de
            qualquer perfil sobre um outro, compreendendo assim a natural diferença comportamental entre as pessoas.
            Entenda o quadrante de Marston, clicando em cada fator DISC:
          </Roboto>
          <Spacer size={2}/>

          <Box display={'flex'}
               flexDirection={'column'}
               style={{backgroundColor: "#294767", borderRadius: '10px', height: '500px'}}>
            <Box display={'flex'}
                 flexDirection={'column'}
                 alignItems={"center"}
                 style={{paddingTop: 15, paddingBottom: 15}}>
              <Roboto
                size={'12px'}
                color={"#FFFFFF"}>
                HOSTIL, ANTAGÔNICO
              </Roboto>
              <Roboto
                size={'10px'}
                color={"#FFFFFF"}>
                (Como percebe o ambiente)
              </Roboto>
            </Box>
            <Box display={'flex'}
                 flexDirection={'row'}
                 flex={1}>
              <Box display={'flex'}
                   alignItems={"center"}
                   justifyContent={"center"}
                   flexDirection={'column'}
                   style={{borderRight: '0.25px solid rgba(255, 255, 255)'}}
                   flex={1}>
                <LightTooltip
                  title={
                    <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}><b>DOMINÂNCIA</b> (D): Uma
                      postura ativa e positiva frente a um ambiente hostil. Confronta de
                      forma a superar e evitar falhas. Características percebidas: Assertivo, Iniciador, Competitivo,
                      Esforçado, Inquisitivo, Direto, Objetivo.
                    </Roboto>
                  }>
                  <Box display={'flex'}
                       flexDirection={'column'}
                       alignItems={"center"}>
                    <Roboto
                      size={'18px'}
                      fontWeight={"bold"}
                      color={"#FFFFFF"}>
                      Dominância
                    </Roboto>
                    <Roboto
                      size={'12px'}
                      color={"#FFFFFF"}>
                      (Poder)
                    </Roboto>
                  </Box>
                </LightTooltip>
              </Box>
              <Box display={'flex'}
                   alignItems={"center"}
                   justifyContent={"center"}
                   flexDirection={'column'}
                   flex={1}>
                <LightTooltip
                  title={
                    <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}><b>CONFORMIDADE</b> (C):
                      Uma reação cautelosa a um ambiente antagônico. Nega o grau de
                      antagonismo e evita problemas e conflitos. Características percebidas: Conforme, Cuidadoso,
                      Sistemático, Preciso, Acurado, Perfeccionista e Lógico.
                    </Roboto>
                  }>
                  <Box display={'flex'}
                       flexDirection={'column'}
                       alignItems={"center"}>
                    <Roboto
                      size={'18px'}
                      fontWeight={"bold"}
                      color={"#FFFFFF"}>
                      Conformidade
                    </Roboto>
                    <Roboto
                      size={'12px'}
                      color={"#FFFFFF"}>
                      (Regras)
                    </Roboto>
                  </Box>
                </LightTooltip>
              </Box>
            </Box>
            <Box display={'flex'}
                 flexDirection={'row'}>
              <Box display={'flex'}
                   flexDirection={'row'}
                   style={{paddingRight: 15, paddingLeft: 15}}>
                <Box display={'flex'}
                     flexDirection={'column'}
                     alignItems={"center"}>
                  <Roboto
                    size={'12px'}
                    color={"#FFFFFF"}>
                    ATIVO
                  </Roboto>
                  <Roboto
                    size={'10px'}
                    color={"#FFFFFF"}>
                    (Reação do indivíduo)
                  </Roboto>
                </Box>
              </Box>
              <Box display={'flex'}
                   flexDirection={'column'}
                   alignItems={"center"}
                   justifyContent={"center"}
                   style={{borderRight: '0.25px solid rgba(255, 255, 255)'}}
                   flex={1}>
                <div style={{border: '0.25px dashed rgba(255, 255, 255)', width: '100%'}}/>
              </Box>
              <Box display={'flex'}
                   flexDirection={'column'}
                   alignItems={"center"}
                   justifyContent={"center"}
                   flex={1}>
                <div style={{border: '0.25px dashed rgba(255, 255, 255)', width: '100%'}}/>
              </Box>
              <Box display={'flex'}
                   flexDirection={'row'}
                   style={{paddingRight: 15, paddingLeft: 15}}>
                <Box display={'flex'}
                     flexDirection={'column'}
                     alignItems={"center"}>
                  <Roboto
                    size={'12px'}
                    color={"#FFFFFF"}>
                    PASSIVO
                  </Roboto>
                  <Roboto
                    size={'10px'}
                    color={"#FFFFFF"}>
                    (Reação do indivíduo)
                  </Roboto>
                </Box>
              </Box>
            </Box>
            <Box display={'flex'}
                 flexDirection={'row'}
                 flex={1}>
              <Box display={'flex'}
                   alignItems={"center"}
                   justifyContent={"center"}
                   flexDirection={'column'}
                   style={{borderRight: '0.25px solid rgba(255, 255, 255)'}}
                   flex={1}>
                <LightTooltip
                  title={
                    <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}><b>INFLUÊNCIA</b> (I): Uma
                      postura ativa e positiva, visando afastar-se de uma situação não
                      amigável em direção a uma situação mais amigável e favorável pela utilização de persuasão,
                      portanto evitando rejeição Características percebidas: Influente, Persuasivo, Amistoso, Verbal,
                      Comunicativo, Positivo e Otimista.
                    </Roboto>
                  }>
                  <Box display={'flex'}
                       flexDirection={'column'}
                       alignItems={"center"}>
                    <Roboto
                      size={'18px'}
                      fontWeight={"bold"}
                      color={"#FFFFFF"}>
                      Influência
                    </Roboto>
                    <Roboto
                      size={'12px'}
                      color={"#FFFFFF"}>
                      (Pessoas)
                    </Roboto>
                  </Box>
                </LightTooltip>
              </Box>
              <Box display={'flex'}
                   alignItems={"center"}
                   justifyContent={"center"}
                   flexDirection={'column'}
                   flex={1}>
                <LightTooltip
                  title={
                    <Roboto size={'14px'} style={{lineHeight: '20px'}} fontWeight={"normal"}><b>ESTABILIDADE</b> (S):
                      Uma reação passiva em um ambiente hostil. Tenta manter o status quo até
                      que a hostilidade passe, de modo a evitar a insegurança. Características percebidas: Confiável,
                      Deliberado, Amável, Persistente, Bom Ouvinte, Gentil e Previsível.
                    </Roboto>
                  }>
                  <Box display={'flex'}
                       flexDirection={'column'}
                       alignItems={"center"}>
                    <Roboto
                      size={'18px'}
                      fontWeight={"bold"}
                      color={"#FFFFFF"}>
                      Estabilidade
                    </Roboto>
                    <Roboto
                      size={'12px'}
                      color={"#FFFFFF"}>
                      (Ritmo)
                    </Roboto>
                  </Box>
                </LightTooltip>
              </Box>
            </Box>
            <Box display={'flex'}
                 flexDirection={'column'}
                 alignItems={"center"}
                 style={{paddingTop: 15, paddingBottom: 15}}>
              <Roboto
                size={'12px'}
                color={"#FFFFFF"}>
                AMIGÁVEL, FAVORAVEL
              </Roboto>
              <Roboto
                size={'10px'}
                color={"#FFFFFF"}>
                (Como percebe o ambiente)
              </Roboto>
            </Box>
          </Box>
          <Spacer size={2}/>

          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            Assista ao vídeo explicativo sobre a metodologia DISC
          </Roboto>
          <Spacer size={2}/>
          <div style={{position: 'relative', paddingTop: '56.25%', overflow: 'hidden'}}>
            <iframe src="https://www.youtube.com/embed/iRQMutzWIMk" frameBorder="0" height="100%" width="100%"
                    allowFullScreen
                    style={{
                      border: 0,
                      height: '100%',
                      left: 0,
                      position: 'absolute',
                      top: 0,
                      width: '100%'
                    }}/>
          </div>
          <Spacer size={5}/>
          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            O PPA DISC da Thomas International
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            A Análise de Perfil Pessoal - PPA foi desenvolvida pelo Dr. Thomas Hendrickson no final dos anos 50. Ele
            levou as conclusões de Marston mais além, produzindo a análise de perfil pessoal para o local de trabalho,
            tornando-se a primeira avaliação DISC do mundo voltada para o uso empresarial e até hoje, mais utilizada
            para identificar perfis de profissionais e executivos para processos seletivos e programas de treinamento.
          </Roboto>
          <Spacer size={2}/>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Box display={"flex"} flex={1}>
              <img
                src={require('../../assets/images/ppa-pagina-modelo.png')}
                alt="Relatório PPA DISC Exemplo"
                width={'100%'}
                style={{flex: 1}}/>
            </Box>
            <Box flex={1}>
              <img
                src={require('../../assets/images/ppa-disc-grafico-modelo.png')}
                alt={"Relatório PPA DISC Exemplo"}
                width={'100%'}
                style={{flex: 1}}/>
            </Box>
            <Box display={"flex"} flexDirection={'column'} flex={1} alignItems={"center"} justifyContent={"center"}>
              <img src={require('../../assets/images/pdf-icon.png')} alt="Relatório PPA DISC Exemplo"
                   width={'50%'}/>
              <Spacer size={2}/>
              <Box display={"flex"} flexDirection={'row'}>
                <Spacer vertical={true} size={2}/>
                <a href={require('../../assets/documents/Modelo de Relatório PPA.pdf')} target={'_blank'}>
                  <Roboto
                    style={{textAlign: 'center'}}
                    size={'16px'}
                    fontWeight={"bold"}>
                    Veja um modelo de relatório PPA DISC
                  </Roboto>
                </a>
                <Spacer vertical={true} size={2}/>
              </Box>
            </Box>
          </Box>
          <Spacer size={2}/>
          <Button variant="contained" color={"secondary"} style={styles.button} disableElevation onClick={() => {
            window.location.href = '/compra/' + this.candidateGuid
          }}>
            {this.state.reports.some(a => !!a.reportTypeId) ? "Acesse agora outros relatórios complementares" : "Acesse agora seu relatório comportamental PPA DISC completo"}
          </Button>
          <Spacer size={5}/>
          <Poppins
            size={'20px'}
            fontWeight={"bold"}>
            Confiabilidade e Validade
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'18px'}
            fontWeight={"normal"}>
            O PPA DISC da Thomas International foi submetido a testes científicos rigorosos para determinar sua
            confiabilidade e validade como uma avaliação psicométrica. O PPA é homologado pela Sociedade Britânica de
            Psicologia (BSP) e é auditado seguindo os critérios técnicos estabelecidos pela European Standing Committee
            on Test and Testing, que faz parte da Federação Europeia de Psicologia.
          </Roboto>
          <Spacer size={2}/>
          <img src={require('../../assets/images/reliability-validity-ppa.png')} alt="DISC Formato" width={'100%'}/>
          <Spacer size={2}/>
          <Button variant="contained" color={"secondary"} style={styles.button} disableElevation onClick={() => {
            window.location.href = '/compra/' + this.candidateGuid
          }}>
            {this.state.reports.some(a => !!a.reportTypeId) ? "Quero obter novos relatórios complementares" : "Quero obter meu relatório completo"}
          </Button>
        </TheColumnsLayout.RightColumnContent>
        <Loading loading={this.state.loading}/>
        <Dialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          open={this.state.dialogShow}
          onClose={() => {
            this.setState({dialogShow: false})
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{this.state.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary"
                    autoFocus
                    onClick={() => {
                      this.setState({dialogShow: false})
                    }}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </TheColumnsLayout>
    );
  }
}

const styles = StyleSheet.create({
  input: {
    width: '100%'
  },
  button: {
    width: '100%',
    padding: '10px',
    minHeight: '56px'
  },
  iconContainer: {
    width: '60px',
    height: '60px',
    backgroundColor: 'rgb(60, 96, 117)',
    borderRadius: 10,
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  }
});
