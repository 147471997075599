import React, {Component} from "react";

export type Props = {};

export type States = {};

export class TheColumnsContentRightLayout extends Component<Props, States> {
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}
