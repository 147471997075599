import axios from 'axios';
import {AccessStorage} from "../storages/AccessStorage";
import AppSetting from "../settings/AppSetting";

export class AuthService {
  static authenticate = async () => {
    let data = new FormData();
    data.set('client_id', AppSetting.authClientId);
    data.set('client_secret', AppSetting.authClientSecret);
    data.set('grant_type', 'client_credentials');

    try {
      let resp = await axios.post(`${AppSetting.authUrl}/connect/token`, data);
      return resp.data;
    } catch (e) {
      return null;
    }

  };

}
