import {AxiosMiddleware} from "../middlewares/AxiosMiddleware";


export class AssessmentService {

  static async requestAssessment(firstName: string, lastName: string, email: string, cpf: string) {
    let user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      cpf: cpf
    };

    return await AxiosMiddleware.post('/api/high-volume/consumer/candidate', user);
  }

  static async sendLoginToken(email: string) {
    return await AxiosMiddleware.post('/api/high-volume/auth/send-token?username=' + email);
  }

  static async getResult(candidateGuide: string) {
    return await AxiosMiddleware.get('/api/high-volume/consumer/candidate/result/' + candidateGuide);
  }

  static async getReports(orderGuid: string) {
    return await AxiosMiddleware.get('/api/assessment/reports/BDC0D9CB-53EA-42E6-B9CF-5B45C756C305/' + orderGuid);
  }

  static async getAvailableProducts(assessmentGuid: string) {
    return await AxiosMiddleware.get('/api/high-volume/store/available-products?assessmentGuid=' + assessmentGuid);
  }

  static async purchaseReports(assessmentGuid: string, data: any) {
    return await AxiosMiddleware.post('/api/high-volume/store/purchase?assessmentGuid=' + assessmentGuid, data);
  }

  static async login(username: string, password: string) {
    let login = {
      username: username,
      password: password
    }

    return await AxiosMiddleware.post('/api/high-volume/auth/login', login);
  }
}
