import React, {Component, CSSProperties} from "react";

export type Props = {
  style?: CSSProperties;
};

export type States = {};

export class TheColumnsContentLeftLayout extends Component<Props, States> {
  render() {
    return (
      <div style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}
