import React, {Component} from "react";
import {StyleSheet} from '../../styles/StyleSheet'
import {Button} from "@material-ui/core";
import {Poppins, Roboto} from "../../components/StyledText";
import {Spacer} from "../../components/Spacer";
import {TheColumnsLayout} from "../../layouts/the-columns/TheColumnsLayout";
import {TextField} from "../../components/TextField";
import {AssessmentService} from "../../services/AssessmentService";
import {Loading} from "../../components/Loading";

export type Props = {};

export type States = {
  firstName: string;
  lastName: string;
  email: string;
  cpf: string;
  password: string;
  loading: boolean;
  success: boolean;
  successField: boolean;
  error: boolean;
  login: boolean;
};

export class AssessmentLoginPage extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      cpf: '',
      password: '',
      loading: false,
      success: false,
      successField: false,
      error: false,
      login: false
    };
  }

  _refFirstName?: TextField | null;
  _refLastName?: TextField | null;
  _refEmail?: TextField | null;
  _refCpf?: TextField | null;
  _refPassword?: TextField | null;

  render() {
    return (
      <TheColumnsLayout>
        <TheColumnsLayout.LeftColumnContent>
          <Poppins
            size={'35px'}
            fontWeight={"bold"}
            color={'#ffffff'}>
            Conheça agora seu perfil comportamental DISC e direcione sua carreira!
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'20px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            Você sabia que em até dez minutos é possível saber o seu atual <b>perfil DISC</b>, e ainda conhecer os
            principais adjetivos que melhor descrevem seu estilo e preferências comportamentais?
          </Roboto>
          <Spacer size={2}/>
          <Roboto
            size={'20px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            E o melhor, é <b>GRATUITO</b> e tudo online!
          </Roboto>
        </TheColumnsLayout.LeftColumnContent>
        <TheColumnsLayout.RightColumnContent>
          <div
            style={styles.validMessage}
            hidden={!this.state.success}>
            <Roboto
              size={'15px'}
              fontWeight={"normal"}
              color={'#ffffff'}>
              Código de acesso enviado com sucesso para o e-mail {this.state.email}
            </Roboto>
          </div>
          <div
            style={styles.errorMessage}
            hidden={!this.state.error}>
            <Roboto
              size={'15px'}
              fontWeight={"normal"}
              color={'#ffffff'}>
              E-mail não encontrado
            </Roboto>
          </div>
          <div
            style={styles.errorMessage}
            hidden={!this.state.login}>
            <Roboto
              size={'15px'}
              fontWeight={"normal"}
              color={'#ffffff'}>
              E-mail e/ou senha inválida!
            </Roboto>
          </div>

          <TextField
            ref={component => (this._refEmail = component)}
            label="Email"
            validate={"email"}
            value={this.state.email}
            required={true}
            onChangeText={async (email: string) => {
              this.setState({email});
            }}
          />

          <div hidden={!this.state.successField}>
            <Spacer size={2}/>
            <TextField
              ref={component => (this._refPassword = component)}
              label="Senha"
              value={this.state.password}
              required={true}
              onChangeText={async (password: string) => {
                this.setState({password});
              }}
            />
            <Spacer size={2}/>
            <Button
              variant="contained" color={"secondary"}
              style={styles.button} disableElevation
              onClick={this._authenticate}>
              Login
            </Button>
          </div>

          <div hidden={this.state.successField}>
            <Spacer size={2}/>
            <Button
              variant="contained" color={"secondary"}
              style={styles.button} disableElevation
              onClick={this._saveOnPress}>
              Acessar resultado
            </Button>
            <Spacer/>
            <Button
              variant="contained" style={{...styles.button, backgroundColor: '#c9c9c9', color: '#ffffff'}}
              disableElevation
              onClick={() => {
                window.location.href = '/'
              }}>
              Voltar
            </Button>
          </div>

        </TheColumnsLayout.RightColumnContent>
        <Loading loading={this.state.loading}/>
      </TheColumnsLayout>
    );
  }

  _saveOnPress = async () => {
    await this.setState({loading: true});

    if (await TextField.fieldsValid([this._refEmail])) {
      await AssessmentService.sendLoginToken(this.state.email)
        .then(data => {
          this._showDetails(true);
        }).catch(async (err) => {
          await this._showDetails(false);
        });
    }

    await this.setState({loading: false});
  };

  _authenticate = async () => {
    await this.setState({loading: true});

    if (await TextField.fieldsValid([this._refEmail, this._refPassword])) {
      await AssessmentService.login(
        this.state.email,
        this.state.password).then(data => {
        if (data['status'] === 'Completed') {
          window.location.href = '/resultado/' + data['candidateGuid'];
        } else {
          window.location.href = data['candidateInvitationUrl'];
        }
      }).catch(async (err) => {
        await this.setState({success: false});
        await this.setState({error: false});
        await this.setState({login: true});
      });
    }

    await this.setState({loading: false});
  };

  _showDetails = async (isSuccess: boolean) => {
    await this.setState({success: isSuccess});
    await this.setState({successField: isSuccess});
    await this.setState({error: !isSuccess});
  };
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
    padding: '10px',
    minHeight: '56px'
  },

  validMessage: {
    padding: '20px',
    backgroundColor: '#9ddd8c',
    borderRadius: '10px',
    marginBottom: '30px'
  },

  errorMessage: {
    padding: '20px',
    backgroundColor: '#f0b9b8',
    borderRadius: '10px',
    marginBottom: '30px'
  }
});
