import React, {Component} from "react";
import {StyleSheet} from '../../styles/StyleSheet'
import {Grid, Hidden} from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import {Roboto} from "../../components/StyledText";
import {ThemeProvider} from "../../themes/ThemeProvider";
import {TheColumnsContentRightLayout} from "./TheColumnsContentRightLayout";
import {TheColumnsContentLeftLayout} from "./TheColumnsContentLeftLayout";
import {Spacer} from "../../components/Spacer";
import {BackgroundColorProperty} from "csstype";

export type Props = {
  leftBackgroundColor?: BackgroundColorProperty;
  style?: React.CSSProperties;
};

export type States = {};

export class TheColumnsLayout extends Component<Props, States> {
  static LeftColumnContent = TheColumnsContentLeftLayout;
  static RightColumnContent = TheColumnsContentRightLayout;

  static defaultProps = {
    leftBackgroundColor: ThemeProvider.colors.primary
  }

  constructor(props: Props) {
    super(props);
  }

  render() {

    return (
      <Grid container style={styles.mainContainer}>
        <Grid
          style={{...styles.leftContainer, backgroundColor: this.props.leftBackgroundColor}}
          direction={"row"}
          xl={4} md={4} sm={12}>
          <Hidden mdDown={true}>
            <Grid lg={4}/>
          </Hidden>
          <Grid lg={8} style={{flex: 1, display: "flex", flexDirection: 'column'}}>
            <Grid style={{flex: 1, flexDirection: 'column'}}>
              {React.Children.map(this.props.children, (child, index) =>
                React.isValidElement(child) && child.type === TheColumnsContentLeftLayout ? React.cloneElement(child, {index}) : null
              )}
            </Grid>
            <Hidden smDown={true}>
              <Grid style={{display: 'flex'}} direction={"row"}>
                <Grid>
                  <Roboto size={'16px'} color={"#FFFFFF"} fontWeight={400}>
                    Powered by
                  </Roboto>
                  <img src={require("../../assets/images/poweredby-thomas-white.svg")} alt="powered bt Thomas"
                       width={130}/>
                </Grid>
                <Spacer vertical={true} size={2}/>
                <Grid alignItems={"flex-end"} style={{display: "flex"}}>
                  <a href="https://www.facebook.com/thomasinternationalbrasil/" target={"_blank"}>
                    <FacebookIcon style={{color: '#FFFFFF'}}/>
                  </a>
                  <Spacer vertical={true} size={0.5}/>
                  <a href="https://twitter.com/thomasint_uk" target={"_blank"}>
                    <TwitterIcon style={{color: '#FFFFFF'}}/>
                  </a>
                  <Spacer vertical={true} size={0.5}/>
                  <a href="https://www.linkedin.com/company/thomas-brasil/" target={"_blank"}>
                    <LinkedInIcon style={{color: '#FFFFFF'}}/>
                  </a>
                  <Spacer vertical={true} size={0.5}/>
                  <a href="https://www.youtube.com/channel/UCe31JeZiTMIV58e4GwgZ7Mg" target={"_blank"}>
                    <YouTubeIcon style={{color: '#FFFFFF'}}/>
                  </a>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
        <Grid style={styles.rightContainer}>
          <Grid style={styles.contentContainer}>
            {React.Children.map(this.props.children, (child, index) =>
              React.isValidElement(child) && child.type === TheColumnsContentRightLayout ? React.cloneElement(child, {index}) : null
            )}
          </Grid>
          <Spacer size={5}/>
          <Grid style={{...styles.footerContainer, display: 'flex', width: '100%', flex: 1}} direction={"column"}
                xs={12}>
            <Hidden mdUp={true}>
              <Grid xs={12}>
                <Grid style={{display: 'flex'}} direction={"row"} xs={12}>
                  <Grid xs={6}>
                    <Roboto size={'16px'} color={ThemeProvider.colors.default} fontWeight={400}>
                      Powered by
                    </Roboto>
                    <img src={require("../../assets/images/poweredby-thomas-black.svg")} alt="powered bt Thomas"
                         width={130}/>
                  </Grid>
                  <Spacer vertical={true} size={2}/>
                  <Grid alignItems={"flex-end"} style={{display: "flex"}} xs={6}>
                    <a href="https://www.facebook.com/thomasinternationalbrasil/" target={"_blank"}>
                      <FacebookIcon style={{color: ThemeProvider.colors.default}}/>
                    </a>
                    <Spacer vertical={true} size={0.5}/>
                    <a href="https://twitter.com/thomasint_uk" target={"_blank"}>
                      <TwitterIcon style={{color: ThemeProvider.colors.default}}/>
                    </a>
                    <Spacer vertical={true} size={0.5}/>
                    <a href="https://www.linkedin.com/company/thomas-brasil/" target={"_blank"}>
                      <LinkedInIcon style={{color: ThemeProvider.colors.default}}/>
                    </a>
                    <Spacer vertical={true} size={0.5}/>
                    <a href="https://www.youtube.com/channel/UCe31JeZiTMIV58e4GwgZ7Mg" target={"_blank"}>
                      <YouTubeIcon style={{color: ThemeProvider.colors.default}}/>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <Spacer size={2}/>
            </Hidden>
            <Grid style={{...styles.footerContainer, display: 'flex', width: '100%'}} alignItems={"flex-end"}
                  direction={"row"} xs={12}>
              <Grid xs={6}>
                <Roboto size={'14px'} color={ThemeProvider.colors.default} fontWeight={"bold"}>
                  Sobre a Thomas
                </Roboto>
                <Spacer size={0.5}/>
                <a href="/faq" target={"_blank"} style={{textDecoration: 'none'}}>
                  <Roboto size={'14px'} color={ThemeProvider.colors.default}>
                    FAQ
                  </Roboto>
                </a>
                <Spacer size={0.5}/>
                <a href="https://www.thomas.co/sites/default/files/2020-04/S%2009_Thomas%20Privacy%20Notice_V3.pdf"
                   target={"_blank"} style={{textDecoration: 'none'}}>
                  <Roboto size={'14px'} color={ThemeProvider.colors.default}>
                    Termos de privacidade
                  </Roboto>
                </a>
              </Grid>
              <Spacer vertical={true} size={2}/>
              <Grid xs={6}>
                <Roboto size={'14px'} color={"#000000"} fontWeight={"bold"}>
                  Contato
                </Roboto>
                <Spacer size={0.5}/>
                <a href="https://www.thomas.co/pt-br" target={"_blank"} style={{textDecoration: 'none'}}>
                  <Roboto size={'14px'} color={"#000000"}>
                    www.thomas.co/pt-br
                  </Roboto>
                </a>
                <Spacer size={0.5}/>
                <a href="tel:+551135496445" target={"_blank"} style={{textDecoration: 'none'}}>
                  <Roboto size={'14px'} color={"#000000"}>
                    11 3549 6445
                  </Roboto>
                </a>
              </Grid>
            </Grid>
          </Grid>


        </Grid>
        {React.Children.map(this.props.children, (child, index) =>
          React.isValidElement(child) && (child.type !== TheColumnsContentLeftLayout && child.type !== TheColumnsContentRightLayout) ? React.cloneElement(child, {index}) : null
        )}
      </Grid>
    );
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    minHeight: '100vh'
  },
  leftContainer: {
    display: "flex",
    paddingTop: ThemeProvider.properties.pageMarginTop,
    paddingRight: ThemeProvider.properties.pageMarginRight,
    paddingBottom: ThemeProvider.properties.pageMarginBottom,
    paddingLeft: ThemeProvider.properties.pageMarginLeft,
    backgroundColor: ThemeProvider.colors.primary
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    flex: 1,
    paddingTop: window.innerWidth >= 960 ? ThemeProvider.properties.pageMarginTop : ThemeProvider.properties.pageMarginBottom,
    paddingRight: ThemeProvider.properties.pageMarginRight,
    paddingBottom: ThemeProvider.properties.pageMarginBottom,
    paddingLeft: ThemeProvider.properties.pageMarginLeft
  },
  contentContainer: {
    flex: 1,
    maxWidth: '800px',
    width: '100%'
  },
  footerContainer: {
    maxWidth: '800px',
    width: '100%'
  },
  input: {
    width: '100%'
  },
  button: {
    width: '100%',
    padding: '10px',
    height: '56px'
  }
});
