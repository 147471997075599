import {ThemeModel} from "./ThemeModel";


export default {
  properties: {
    space: '10px',
    roundness: 10,
    pageMarginLeft: '45px',
    pageMarginRight: '45px',
    pageMarginTop: '100px',
    pageMarginBottom: '45px'
  },
  colors: {
    default: '#343732',

    primary: '#69bebc',
    primaryLight: '#69bebc',

    secondary: '#6b5ea4',
    secondaryLight: '#6b5ea4',

    success: '#B6D178',
    successLight: '#D7E9AF',

    danger: '#dd545c',
    dangerLight: '#dd545c',

    warning: '#CCBF1F',
    warningLight: '#CCC692'
  },
  headingTags: {
    h1: {
      fontSize: '40px'
    },
    h2: {
      fontSize: '30px'
    },
    h3: {
      fontSize: '30px'
    },
    h4: {
      fontSize: '30px'
    },
    h5: {
      fontSize: '30px'
    },
    h6: {
      fontSize: '30px'
    }
  },
  paragraphTags: {
    p1: {
      fontSize: '30px'
    },
    p2: {
      fontSize: '30px'
    },
    p3: {
      fontSize: '30px'
    },
    p4: {
      fontSize: '30px'
    },
    p5: {
      fontSize: '30px'
    }
  }
} as ThemeModel
