import React, {Component} from "react";
import {StyleSheet} from '../../styles/StyleSheet'
import {Box, Button} from "@material-ui/core";
import {Poppins, Roboto} from "../../components/StyledText";
import {Spacer} from "../../components/Spacer";
import {TheColumnsLayout} from "../../layouts/the-columns/TheColumnsLayout";
import {TextField} from "../../components/TextField";
import {AssessmentService} from "../../services/AssessmentService";
import {Loading} from "../../components/Loading";
import {ThemeProvider} from "../../themes/ThemeProvider";
import ScheduleIcon from '@material-ui/icons/Schedule';
import PublicIcon from '@material-ui/icons/Public';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import queryString from 'query-string'

export type Props = {
  location: any;
};

export type States = {
  firstName: string;
  lastName: string;
  email: string;
  cpf: string;
  loading: boolean;
  readonlyEmail: boolean;
};

export class AssessmentRegisterPage extends Component<Props, States> {
  email: string = window.location.href.split('/')[window.location.href.split('/').length - 1];
  _refFirstName?: TextField | null;
  _refLastName?: TextField | null;
  _refEmail?: TextField | null;
  _refCpf?: TextField | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      cpf: '',
      loading: false,
      readonlyEmail: false
    };
  }

  async componentDidMount() {
    let values: any = queryString.parse(this.props.location.search)
    await this.setState({email: values['email']});
    await this.setState({readonlyEmail: !!values['email']})
  }

  render() {

    let queries = queryString.parse(window.location.search);
    let emailLogUrl = `https://thomas-api.azurewebsites.net/api/v1/basic/EmailLog?email=${queries['meuppa_email']}&action=load_page_register&subject=${queries['utm_campaign']}`;

    return (
      <TheColumnsLayout>
        <TheColumnsLayout.LeftColumnContent>
          <Poppins
            size={'35px'}
            fontWeight={"bold"}
            color={'#ffffff'}>
            Conheça agora seu perfil comportamental DISC e direcione sua carreira!
          </Poppins>
          <Spacer size={2}/>
          <Roboto
            size={'20px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            Você sabia que em até dez minutos é possível saber o seu atual <b>perfil DISC</b>, e ainda conhecer os
            principais adjetivos que melhor descrevem seu estilo e preferências comportamentais?
          </Roboto>
          <Spacer size={2}/>
          <Roboto
            size={'20px'}
            fontWeight={"normal"}
            color={'#ffffff'}>
            E o melhor, é <b>GRATUITO</b> e tudo online!
          </Roboto>
        </TheColumnsLayout.LeftColumnContent>
        <TheColumnsLayout.RightColumnContent>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'center'}>
              <ScheduleIcon style={{color: 'rgb(105, 190, 188)', fontSize: '40px'}}/>
              <Spacer/>
              <Roboto
                style={{textAlign: 'center'}}
                size={'22px'}
                fontWeight={"bold"}
                color={'#767171'}>
                Rápido e preciso
              </Roboto>
              <Spacer size={0.5}/>
              <Roboto
                style={{textAlign: 'center'}}
                size={'18px'}
                fontWeight={"normal"}
                color={'#767171'}>
                Menos de 10 minutos para responder o teste
              </Roboto>
            </Box>
            <Spacer size={2} vertical={true}/>
            <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'center'}>
              <PublicIcon style={{color: 'rgb(105, 190, 188)', fontSize: '40px'}}/>
              <Spacer/>
              <Roboto
                style={{textAlign: 'center'}}
                size={'22px'}
                fontWeight={"bold"}
                color={'#767171'}>
                Avaliação mais utilizada no mundo
              </Roboto>
              <Spacer size={0.5}/>
              <Roboto
                style={{textAlign: 'center'}}
                size={'18px'}
                fontWeight={"normal"}
                color={'#767171'}>
                + 20 milhões de pessoas já responderam sua avaliação
              </Roboto>
            </Box>
            <Spacer size={2} vertical={true}/>
            <Box display={'flex'} flexDirection={'column'} flex={1} alignItems={'center'}>
              <ThumbUpIcon style={{color: 'rgb(105, 190, 188)', fontSize: '40px'}}/>
              <Spacer/>
              <Roboto
                style={{textAlign: 'center'}}
                size={'22px'}
                fontWeight={"bold"}
                color={'#767171'}>
                Empresas usam e aprovam
              </Roboto>
              <Spacer size={0.5}/>
              <Roboto
                style={{textAlign: 'center'}}
                size={'18px'}
                fontWeight={"normal"}
                color={'#767171'}>
                + 32.000 empresas no mundo utilizam esta avaliação
              </Roboto>
            </Box>
          </Box>
          <Spacer size={4}/>
          <TextField
            ref={component => (this._refFirstName = component)}
            label="Nome"
            value={this.state.firstName}
            required={true}
            onChangeText={async (firstName: string) => {
              this.setState({firstName});
            }}
          />
          <Spacer/>
          <TextField
            ref={component => (this._refLastName = component)}
            label="Sobrenome"
            value={this.state.lastName}
            required={true}
            onChangeText={async (lastName: string) => {
              this.setState({lastName});
            }}
          />
          <Spacer/>
          <TextField
            ref={component => (this._refEmail = component)}
            label="Email"
            editable={!this.state.readonlyEmail}
            validate={"email"}
            value={this.state.email}
            required={true}
            onChangeText={async (email: string) => {
              this.setState({email});
            }}
          />
          <Spacer/>
          <TextField
            ref={component => (this._refCpf = component)}
            label="CPF"
            value={this.state.cpf}
            validate={"cpf"}
            mask={"cpf"}
            required={true}
            onChangeText={async (cpf: string) => {
              this.setState({cpf});
            }}
          />
          <Spacer size={2}/>
          <Button variant="contained" color={"secondary"} style={styles.button} disableElevation
                  onClick={this._saveOnPress}>
            Quero responder meu teste, gratuitamente, agora!
          </Button>
          <Spacer/>
          <div style={{textAlign: "center"}}>
            <Roboto
              size={'18px'}
              fontWeight={"normal"}
              color={'#000000'}>
              Ja respondeu o questionário? Para acessar seu resultado&nbsp;
              <a style={{fontWeight: "bold", color: ThemeProvider.colors.danger}} href={`/login`}>clique aqui</a>
            </Roboto>
          </div>
        </TheColumnsLayout.RightColumnContent>
        <Loading loading={this.state.loading}/>
        <img src={emailLogUrl} alt=""/>
      </TheColumnsLayout>
    );
  }

  _saveOnPress = async () => {
    await this.setState({loading: true});

    if (await TextField.fieldsValid([this._refFirstName, this._refLastName, this._refEmail, this._refCpf])) {
      await AssessmentService.requestAssessment(
        this.state.firstName,
        this.state.lastName,
        this.state.email,
        this.state.cpf).then(data => {
        if (data['status'] === 'Completed') {
          window.location.href = '/login';
        } else {
          window.location.href = data['candidateInvitationUrl'];
        }
      });
    }

    await this.setState({loading: false});
  };
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
    padding: '10px',
    minHeight: '56px'
  }
});
